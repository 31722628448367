export const editedUpdate = (obj, payload, extraField = null) => {
  const newObj = obj ? [...obj] : [];

  const updated = extraField
    ? [payload, ...deleteFromState(newObj, payload[extraField].id, extraField)]
    : [payload, ...deleteFromState(newObj, payload.id, extraField)];

  return updated;
};

export const created = (obj, payload) => {
  const newObj = obj ? [...obj] : [];
  return [payload, ...newObj];
};

export const deleteFromState = (obj, objId, extraField = null) => {
  const newObj = obj ? [...obj] : [];
  return newObj.filter((obj) => {
    if (extraField) {
      return obj[extraField].id !== objId;
    }
    return obj.id !== objId;
  });
};

export const editPaginated = (obj, payload) => {
  const newObj = obj ? { ...obj } : {};
  const results = editedUpdate(obj['result'], payload);
  newObj['result'] = results;
  newObj['count'] = results.length;
  return newObj;
};

export const createPaginated = (obj, payload) => {
  const newObj = obj ? { ...obj } : {};
  const results = created(obj['result'], payload);
  newObj['result'] = results;
  newObj['count'] = results.length;
  return newObj;
};

export const deletePaginated = (obj, payload) => {
  const newObj = obj ? { ...obj } : {};
  const results = deleteFromState(newObj['result'], payload);
  newObj['result'] = results;
  newObj['count'] = results.length;
  return newObj;
};
