import {
	created,
	editedUpdate,
	deleteFromState,
} from '../../helpers/reduxUtils';

import {
	SENDER_FEE_LOADING,
	SENDER_FEE_LOADED,
	SENDER_FEE_EDITED,
	SENDER_FEE_CREATED,
	SENDER_FEE_DELETED,
	SENDER_FEE_FAILED,
	FEE_SCALE_LOADING,
	FEE_SCALE_LOADED,
	FEE_SCALE_EDITED,
	FEE_SCALE_DELETED,
	FEE_SCALE_CREATED,
	FEE_SCALE_FAILED,
	LOGOUT,
} from '../types';

const initialState = {
	loading: false,
	scales: [],
	fees: [],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case FEE_SCALE_LOADING:
		case SENDER_FEE_LOADING:
			return {
				...state,
				loading: true,
			};
		case SENDER_FEE_LOADED:
			return {
				...state,
				loading: false,
				fees: action.payload,
			};
		case FEE_SCALE_LOADED:
			return {
				...state,
				loading: false,
				scales: action.payload,
			};
		case SENDER_FEE_CREATED:
			return {
				...state,
				loading: false,
				fees: created(state.fees, action.payload),
			};
		case FEE_SCALE_CREATED:
			return {
				...state,
				loading: false,
				scales: created(state.scales, action.payload),
			};
		case SENDER_FEE_EDITED:
			return {
				...state,
				loading: false,
				fees: editedUpdate(state.fees, action.payload),
			};
		case FEE_SCALE_EDITED:
			return {
				...state,
				loading: false,
				scales: editedUpdate(state.scales, action.payload),
			};
		case SENDER_FEE_DELETED:
			return {
				...state,
				loading: false,
				fees: deleteFromState(state.fees, action.payload),
			};
		case FEE_SCALE_DELETED:
			return {
				...state,
				loading: false,
				scales: deleteFromState(state.scales, action.payload),
			};
		case SENDER_FEE_FAILED:
		case FEE_SCALE_FAILED:
			return {
				...state,
				loading: false,
			};
		case LOGOUT:
			return {
				loading: false,
				scales: [],
				fees: [],
			};
		default:
			return state;
	}
}
