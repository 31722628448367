import { defaultLocale, localeOptions } from '../../constants/defaultValues';

import {
	created,
	editedUpdate,
	deleteFromState,
} from '../../helpers/reduxUtils';

import {
	CHANGE_LOCALE,
	KLASS_LOADING,
	KLASS_LOADED,
	KLASS_CREATED,
	KLASS_EDITED,
	KLASS_DELETED,
	KLASS_FAILED,
	KLASSGROUP_LOADING,
	KLASSGROUP_LOADED,
	KLASSGROUP_CREATED,
	KLASSGROUP_DELETED,
	KLASSGROUP_FAILED,
	LOGOUT,
} from '../types';

const INIT_STATE = {
	loading: false,
	klasses: [],
	klassGroup: [],
	locale:
		localStorage.getItem('currentLanguage') &&
		localeOptions.filter(
			(x) => x.id === localStorage.getItem('currentLanguage')
		).length > 0
			? localStorage.getItem('currentLanguage')
			: defaultLocale,
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case CHANGE_LOCALE:
			return { ...state, locale: action.payload };

		case KLASSGROUP_LOADING:
		case KLASS_LOADING:
			return {
				...state,
				loading: true,
			};
		case KLASS_LOADED:
			return {
				...state,
				loading: false,
				klasses: action.payload,
			};
		case KLASS_CREATED:
			return {
				...state,
				loading: false,
				klasses: created(state.klasses, action.payload),
			};
		case KLASS_EDITED:
			return {
				...state,
				loading: false,
				klasses: editedUpdate(state.klasses, action.payload),
			};
		case KLASS_DELETED:
			return {
				...state,
				loading: false,
				klasses: deleteFromState(state.klasses, action.payload),
			};
		case KLASSGROUP_LOADED:
			return { ...state, loading: false, klassGroup: action.payload };
		case KLASSGROUP_CREATED:
			return {
				...state,
				loading: false,
				klassGroup: created(state.klassGroup, action.payload),
			};
		case KLASSGROUP_DELETED:
			return {
				...state,
				loading: false,
				klassGroup: deleteFromState(state.klassGroup, action.payload),
			};
		case KLASS_FAILED:
		case KLASSGROUP_FAILED:
			return { ...state, loading: false };
		case LOGOUT:
			return {
				...state,
				loading: false,
				klasses: [],
				klassGroup: [],
			};
		default:
			return { ...state };
	}
};
