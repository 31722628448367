import {
	LOGOUT,
	AUTH_FAILED,
	USER_LOADED,
	USER_EDITED,
	USER_FAILED,
	AUTH_LOADING,
	LOGIN_FAILED,
	LOGIN_SUCCESSFUL,
	ACCOUNT_LOADING,
	ACCOUNT_LOADED,
	ACCOUNT_FAILED,
	REGISTRATION_COMPLETED,
	REGISTRATION_FAILED,
	REGISTRATION_SUCCESSFUL,
	REGISTRATION_LOADING,
	RESETTING_PASSWORD,
	RESET_PASSWORD_SUCCESS,
	PASSWORD_RESET_SUCCESS,
	PASSWORD_RESET_FAILED,
	AUTH_PROVIDER_LOADING,
	AUTH_PROVIDER_LOADED,
	AUTH_PROVIDER_FAILED,
} from '../types';

const initialState = {
	user: null,
	authRule: 0,
	sender: null,
	loading: false,
	currentBal: 0.0,
	provider: null,
	authoriserExist: false,
	isAuthenticated: false,
	token: localStorage.getItem('fastpayrAUTHTOKEN'),
	tokenPresent:
		localStorage.getItem('fastpayrAUTHTOKEN') !== null &&
		localStorage.getItem('fastpayrAUTHTOKEN') !== undefined,
	success: false,
	successPayload: null,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case AUTH_PROVIDER_LOADING:
		case REGISTRATION_LOADING:
		case RESETTING_PASSWORD:
		case ACCOUNT_LOADING:
		case AUTH_LOADING:
			return {
				...state,
				loading: true,
				success: false,
				successPayload: null,
			};
		case ACCOUNT_LOADED:
		case ACCOUNT_FAILED:
			return {
				...state,
				loading: false,
			};
		case USER_LOADED:
		case LOGIN_SUCCESSFUL:
		case REGISTRATION_COMPLETED:
			if (action.payload.token) {
				localStorage.setItem('fastpayrAUTHTOKEN', action.payload.token);
			}
			return {
				...state,
				...action.payload,
				loading: false,
				tokenPresent: true,
				isAuthenticated: true,
			};
		case USER_EDITED:
			return {
				...state,
				...action.payload,
				loading: false,
			};
		case RESET_PASSWORD_SUCCESS:
			return {
				...state,
				success: true,
				loading: false,
				tokenPresent: false,
				isAuthenticated: false,
			};
		case AUTH_PROVIDER_LOADED:
			return {
				...state,
				loading: false,
				provider: action.payload,
			};
		case USER_FAILED:
			return {
				...state,
				loading: false,
			};
		case LOGOUT:
		case AUTH_FAILED:
		case LOGIN_FAILED:
		case REGISTRATION_FAILED:
		case AUTH_PROVIDER_FAILED:
		case PASSWORD_RESET_FAILED:
		case PASSWORD_RESET_SUCCESS:
		case REGISTRATION_SUCCESSFUL:
			localStorage.removeItem('fastpayrAUTHTOKEN');
			return {
				...state,
				user: null,
				token: null,
				authRule: 0,
				sender: null,
				provider: null,
				loading: false,
				currentBal: 0.0,
				tokenPresent: false,
				isAuthenticated: false,
				authoriserExist: false,
			};
		default:
			return state;
	}
}
