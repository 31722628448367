import {
  RESET_MESSAGE,
  REGISTRATION_COMPLETED,
  REGISTRATION_SUCCESSFUL,
  PASSWORD_RESET_SUCCESS,
} from '../types';

const initialState = {
  success: false,
  message: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PASSWORD_RESET_SUCCESS:
    case REGISTRATION_SUCCESSFUL:
    case REGISTRATION_COMPLETED:
      return {
        ...state,
        success: true,
        message: action.payload,
      };
    case RESET_MESSAGE:
      return {
        ...state,
        success: false,
        message: null,
      };
    default:
      return state;
  }
}
