import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import auth from './auths/reducers.js';
import messages from './message/reducers';
import authorisers from './authorisers/reducers';
import wallet from './wallet/reducers';
import receivers from './receivers/reducers';
import system from './system/reducers';
import sender from './sender/reducers';
import pay from './pay/reducers';
import products from './products/reducers';
import sp from './provider/reducers';

const reducers = combineReducers({
	sp,
	pay,
	auth,
	menu,
	sender,
	wallet,
	system,
	settings,
	products,
	messages,
	receivers,
	authorisers,
});

export default reducers;
