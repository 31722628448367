import {
	PAYMENT_CANCELLED,
	CREATING_PAYMENT,
	PAYMENT_POSTED,
	PAYMENT_LOADED,
	PAYMENT_LOADING,
	PAYMENT_FAILED,
	PAYMENT_DETAILS,
	RESET_STATUS,
	TRANSACTION_QUERY_LOADING,
	TRANSACTION_QUERY_LOADED,
	TRANSACTION_QUERY_FAILED,
	PAYMENTMETHOD_LOADING,
	PAYMENTMETHOD_LOADED,
	PAYMENTMETHOD_FAILED,
	LOGOUT,
} from '../types';

const initialState = {
	loading: false,
	success: false,
	payments: [],
	payment: null,
	transQuery: null,
	paymentMethods: [],
};

export default function (state = initialState, action) {
	let obj;
	switch (action.type) {
		case TRANSACTION_QUERY_LOADING:
		case PAYMENTMETHOD_LOADING:
		case PAYMENT_LOADING:
		case CREATING_PAYMENT:
			return {
				...state,
				loading: true,
				success: false,
			};
		case PAYMENT_LOADED:
			return {
				...state,
				loading: false,
				success: false,
				payments: action.payload,
			};
		case PAYMENTMETHOD_LOADED:
			return {
				...state,
				loading: false,
				success: false,
				paymentMethods: action.payload,
			};
		case PAYMENT_POSTED:
			return {
				...state,
				loading: false,
				success: true,
				payments: [action.payload, ...state.payments],
			};
		case PAYMENT_DETAILS:
			return {
				...state,
				loading: false,
				payment: action.payload,
			};
		case TRANSACTION_QUERY_LOADED:
			return {
				...state,
				loading: false,
				transQuery: action.payload,
			};
		case PAYMENT_CANCELLED:
			obj = { ...state.payment };
			obj['payment']['cancelled'] = true;
			return {
				...state,
				loading: false,
				payment: { ...obj },
			};
		case TRANSACTION_QUERY_FAILED:
		case RESET_STATUS:
		case PAYMENTMETHOD_FAILED:
		case PAYMENT_FAILED:
			return {
				...state,
				loading: false,
				success: false,
				payment: null,
				transQuery: null,
			};
		case LOGOUT:
			return {
				loading: false,
				success: false,
				payments: [],
				payment: null,
				transQuery: null,
				paymentMethods: [],
			};
		default:
			return state;
	}
}
