import { created, editedUpdate } from '../../helpers/reduxUtils';

import {
    WALLET_LOADING,
    WALLET_FUND_POSTED,
    WALLET_TRANS_LOADED,
    WALLET_FUND_FAILED,
    FUNDS_LOADING,
    FUNDS_LOADED,
    FUNDS_DETAILS,
    FUNDS_APPROVED,
    FUNDS_FAILED,
    RESET_STATUS,
    LOGOUT,
    WALLET_TRANSACTION_LOADED,
    WALLET_ACCOUNT_SEARCHED,
    WALLET_ACCOUNT_LOADED,
    WALLET_FAILED,
} from '../types';

const initialState = {
    loading: false,
    trans: [],
    funds: [],
    fund: null,
    accounts: [],
    transactions: [],
    transLoaded: false,
    accountsSearched: [],
};

export default function(state = initialState, action) {
    switch (action.type) {
        case FUNDS_LOADING:
        case WALLET_LOADING:
            return {
                ...state,
                loading: true,
            };
        case FUNDS_LOADED:
            return {
                ...state,
                loading: false,
                fund: null,
                funds: action.payload,
            };
        case FUNDS_DETAILS:
            return {
                ...state,
                loading: false,
                fund: action.payload,
            };
        case FUNDS_APPROVED:
            return {
                ...state,
                loading: false,
                fund: action.payload,
                funds: editedUpdate(state.funds, action.payload),
            };
        case RESET_STATUS:
            return {
                ...state,
                loading: false,
                fund: null,
            };
        case WALLET_FUND_POSTED:
            return {
                ...state,
                loading: false,
                trans: created(state.trans, action.payload),
            };
        case WALLET_TRANS_LOADED:
            return {
                ...state,
                loading: false,
                trans: action.payload,
                transLoaded: true,
            };
        case FUNDS_FAILED:
        case WALLET_FUND_FAILED:
            return {
                ...state,
                loading: false,
            };
        case WALLET_ACCOUNT_LOADED:
            return {
                ...state,
                loading: false,
                accounts: action.payload,
            };

        case WALLET_ACCOUNT_SEARCHED:
            return {
                ...state,
                loading: false,
                accountsSearched: action.payload,
            };

        case WALLET_TRANSACTION_LOADED:
            return {
                ...state,
                loading: false,
                transactions: action.payload,
            };

        case WALLET_FAILED:
            return {
                ...state,
                loading: false,
            };
        case LOGOUT:
            return {
                ...initialState,
            };
        default:
            return state;
    }
}