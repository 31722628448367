import {
    PRODUCT_LOADING,
    PRODUCT_LOADED,
    PRODUCT_FAILED,
    PRODUCT_TRANSACTION_LOADING,
    PRODUCT_TRANSACTION_LOADED,
    PRODUCT_TRANSACTION_FAILED,
    PRODUCT_FEESCALE_LOADING,
    PRODUCT_FEESCALE_LOADED,
    PRODUCT_FEESCALE_CREATED,
    PRODUCT_FEESCALE_EDITED,
    PRODUCT_FEESCALE_DELETED,
    PRODUCT_FEESCALE_FAILED,
    PRODUCTCATEGORY_LOADING,
    PRODUCTCATEGORY_LOADED,
    PRODUCTCATEGORY_CREATED,
    PRODUCTCATEGORY_EDITED,
    PRODUCTCATEGORY_DELETED,
    PRODUCTCATEGORY_FAILED,
    DOWNLOADED_RECEIVED,
    TRANSACTION_REVERSAL_LOADING,
    TRANSACTION_REVERSAL_SUCCESS,
    TRANSACTION_REVERSAL_FAILED,
    TRANSACTION_DAILY_REPORT_LOADING,
    TRANSACTION_DAILY_REPORT_LOADED,
    TRANSACTION_DAILY_REPORT_FAILED,
    LOGOUT,
} from '../types';
import {
    created,
    editedUpdate,
    deleteFromState,
} from '../../helpers/reduxUtils';

const initialState = {
    loading: false,
    products: [],
    productTrans: [],
    dailyReport: [],
    productFee: [],
    productcategories: [],
};

export default function(state = initialState, action) {
    switch (action.type) {
        case PRODUCT_LOADING:
        case PRODUCTCATEGORY_LOADING:
        case PRODUCT_FEESCALE_LOADING:
        case PRODUCT_TRANSACTION_LOADING:
        case TRANSACTION_REVERSAL_LOADING:
        case TRANSACTION_DAILY_REPORT_LOADING:
            return {
                ...state,
                loading: true,
            };
        case TRANSACTION_DAILY_REPORT_LOADED:
            return {
                ...state,
                loading: false,
                dailyReport: action.payload,
            };
        case PRODUCTCATEGORY_LOADED:
            return {
                ...state,
                loading: false,
                productcategories: action.payload,
            };
        case DOWNLOADED_RECEIVED:
            return {
                ...state,
                loading: false,
            };
        case PRODUCT_LOADED:
            return {
                ...state,
                loading: false,
                products: action.payload,
            };
        case PRODUCT_FEESCALE_LOADED:
            return {
                ...state,
                loading: false,
                productFee: action.payload,
            };
        case PRODUCTCATEGORY_CREATED:
            return {
                ...state,
                loading: false,
                productcategories: created(state.productcategories, action.payload),
            };
        case PRODUCT_FEESCALE_CREATED:
            return {
                ...state,
                loading: false,
                productFee: created(state.productFee, action.payload),
            };
        case PRODUCTCATEGORY_EDITED:
            return {
                ...state,
                loading: false,
                productcategories: editedUpdate(
                    state.productcategories,
                    action.payload
                ),
            };
        case PRODUCT_FEESCALE_EDITED:
            return {
                ...state,
                loading: false,
                productFee: editedUpdate(state.productFee, action.payload),
            };
        case PRODUCT_FEESCALE_DELETED:
            return {
                ...state,
                loading: false,
                productFee: deleteFromState(state.productFee, action.payload),
            };
        case PRODUCTCATEGORY_DELETED:
            return {
                ...state,
                loading: false,
                productcategories: deleteFromState(
                    state.productcategories,
                    action.payload
                ),
            };
        case PRODUCT_TRANSACTION_LOADED:
            return {
                ...state,
                loading: false,
                productTrans: action.payload,
            };
        case TRANSACTION_DAILY_REPORT_FAILED:
        case TRANSACTION_REVERSAL_SUCCESS:
        case TRANSACTION_REVERSAL_FAILED:
        case PRODUCT_TRANSACTION_FAILED:
        case PRODUCT_FEESCALE_FAILED:
        case PRODUCTCATEGORY_FAILED:
        case PRODUCT_FAILED:
            return {
                ...state,
                loading: false,
            };
        case LOGOUT:
            return {...initialState };
        default:
            return state;
    }
}