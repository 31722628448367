import {
  created,
  editedUpdate,
  deleteFromState,
} from '../../helpers/reduxUtils';

import {
  AUTHORISER_LOADING,
  AUTHORISER_LOADED,
  AUTHORISER_CREATED,
  AUTHORISER_EDITED,
  AUTHORISER_FAILED,
  AUTHORISER_CHANGE_STATUS,
  PAYRULE_LOADING,
  PAYRULE_LOADED,
  PAYRULE_CREATED,
  PAYRULE_EDITED,
  PAYRULE_DELETE,
  PAYRULE_FAILED,
} from '../types';

const initialState = {
  loading: false,
  payRules: null,
  authorisers: null,
};

export default function (state = initialState, action) {
  let authorisers, obj;
  switch (action.type) {
    case PAYRULE_LOADING:
    case AUTHORISER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case AUTHORISER_EDITED:
      obj = state.authorisers ? { ...state.authorisers } : {};
      authorisers = [...obj.authorisers];
      authorisers = authorisers.filter(
        (a) => a['authoriser'].id !== action.payload.authoriser.id
      );
      authorisers.unshift(action.payload);
      obj['authorisers'] = [...authorisers];
      return {
        ...state,
        loading: false,
        authorisers: obj,
      };
    case AUTHORISER_CHANGE_STATUS:
      obj = state.authorisers ? { ...state.authorisers } : {};
      authorisers = [...obj.authorisers];
      authorisers = authorisers.filter(
        (a) => a['authoriser'].id !== action.payload.id
      );
      authorisers.unshift(action.payload);
      obj['authorisers'] = [...authorisers];
      return {
        ...state,
        loading: false,
        authorisers: obj,
      };
    case PAYRULE_EDITED:
      return {
        ...state,
        loading: false,
        payRules: editedUpdate(state.payRules, action.payload, 'authRule'),
      };
    case PAYRULE_CREATED:
      return {
        ...state,
        loading: false,
        payRules: created(state.payRules, action.payload),
      };
    case PAYRULE_DELETE:
      return {
        ...state,
        loading: false,
        payRules: deleteFromState(state.payRules, action.payload, 'authRule'),
      };
    case AUTHORISER_CREATED:
      obj = state.authorisers ? { ...state.authorisers } : {};
      authorisers = [...obj.authorisers];
      authorisers.unshift(action.payload);
      obj['authorisers'] = [...authorisers];
      return {
        ...state,
        loading: false,
        authorisers: obj,
      };
    case PAYRULE_LOADED:
      return {
        ...state,
        loading: false,
        payRules: action.payload,
      };
    case AUTHORISER_LOADED:
      return {
        ...state,
        loading: false,
        authorisers: action.payload,
      };
    case PAYRULE_FAILED:
    case AUTHORISER_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
