import {
	PROVIDERS_LOADING,
	PROVIDERS_LOADED,
	PROVIDERS_CREATED,
	PROVIDERS_EDITED,
	PROVIDERS_DELETED,
	PROVIDERS_DETAILS,
	PROVIDERS_FAILED,
	SP_PRODUCTS_LOADING,
	SP_PRODUCTS_LOADED,
	SP_PRODUCTS_CREATED,
	SP_PRODUCTS_EDITED,
	SP_PRODUCTS_DELETED,
	SP_PRODUCTS_FAILED,
	SP_MANAGER_LOADING,
	SP_MANAGER_CREATED,
	SP_MANAGER_EDITED,
	SP_MANAGER_FAILED,
	PROVIDERS_DETAILS_RESET,
	SETTLEMENT_LOADING,
	SETTLEMENT_LOADED,
	SETTLEMENT_FAILED,
	SETTLEMENT_RESET,
	LOGOUT,
} from '../types';

import {
	created,
	editedUpdate,
	deleteFromState,
} from '../../helpers/reduxUtils';

const initialState = {
	loading: false,
	providers: [],
	products: [],
	provider: null,
	sucess: false,
	settlement: null,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SP_PRODUCTS_LOADING:
		case SP_MANAGER_LOADING:
		case PROVIDERS_LOADING:
		case SETTLEMENT_LOADING:
			return {
				...state,
				loading: true,
			};
		case SETTLEMENT_LOADED:
			return {
				...state,
				loading: false,
				settlement: action.payload,
			};
		case PROVIDERS_LOADED:
			return {
				...state,
				success: false,
				loading: false,
				providers: action.payload,
			};
		case PROVIDERS_CREATED:
			return {
				...state,
				loading: false,
				success: true,
				providers: created(state.providers, action.payload),
			};
		case PROVIDERS_EDITED:
			return {
				...state,
				success: true,
				loading: false,
				providers: editedUpdate(state.providers, action.payload),
			};
		case PROVIDERS_DELETED:
			return {
				...state,
				loading: false,
				providers: deleteFromState(state.providers, action.payload),
			};

		case SP_PRODUCTS_LOADED:
			return {
				...state,
				loading: false,
				products: action.payload,
			};
		case SP_PRODUCTS_CREATED:
			return {
				...state,
				loading: false,
				products: created(state.products, action.payload),
			};
		case SP_PRODUCTS_EDITED:
			return {
				...state,
				loading: false,
				products: editedUpdate(state.products, action.payload),
			};
		case SP_PRODUCTS_DELETED:
			return {
				...state,
				loading: false,
				products: deleteFromState(state.products, action.payload),
			};
		case PROVIDERS_DETAILS:
			return {
				...state,
				loading: false,
				success: false,
				provider: action.payload,
			};
		case SP_MANAGER_EDITED:
		case SP_MANAGER_CREATED:
			return {
				...state,
				loading: false,
				providers: editedUpdate(state.providers, action.payload),
			};
		case SETTLEMENT_RESET:
		case SETTLEMENT_FAILED:
			return {
				...state,
				loading: false,
				settlement: null,
			};
		case SP_PRODUCTS_FAILED:
		case SP_MANAGER_FAILED:
		case PROVIDERS_FAILED:
			return {
				...state,
				loading: false,
			};

		case PROVIDERS_DETAILS_RESET:
			return {
				...state,
				provider: null,
			};
		case LOGOUT:
			return {
				loading: false,
				providers: [],
				products: [],
				provider: null,
				sucess: false,
				settlement: null,
			};
		default:
			return state;
	}
}
