import {
	createPaginated,
	deletePaginated,
	editPaginated,
	editedUpdate,
	created,
	deleteFromState,
} from '../../helpers/reduxUtils';

import {
	LOGOUT,
	RECEIVER_LOADING,
	RECEIVER_LOADED,
	RECEIVER_CREATED,
	RECEIVER_EDITED,
	RECEIVER_FAILED,
	RECEIVER_DELETED,
	RECEIVER_SEARCHED,
	RECEIVER_GROUP_LOADING,
	RECEIVER_GROUP_LOADED,
	RECEIVER_GROUP_CREATED,
	RECEIVER_GROUP_EDITED,
	RECEIVER_GROUP_DELETE,
	RECEIVER_GROUP_FAILED,
	BULK_RECEIVER_UPLOADING,
	BULK_RECEIVER_UPLOADED,
	BULK_RECEIVER_FAILED,
} from '../types';

const initialState = {
	loading: false,
	groups: null,
	receivers: null,
	searchResults: [],
	isSuccessful: false,
};

export default function (state = initialState, action) {
	let obj, result;
	switch (action.type) {
		case BULK_RECEIVER_UPLOADING:
		case RECEIVER_GROUP_LOADING:
		case RECEIVER_LOADING:
			return {
				...state,
				loading: true,
				isSuccessful: false,
			};
		case RECEIVER_CREATED:
			return {
				...state,
				loading: false,
				receivers: createPaginated(state.receivers, action.payload),
			};
		case RECEIVER_GROUP_CREATED:
			return {
				...state,
				loading: false,
				groups: created(state.groups, action.payload),
			};
		case RECEIVER_EDITED:
			return {
				...state,
				loading: false,
				receivers: editPaginated(state.receivers, action.payload),
			};
		case RECEIVER_DELETED:
			return {
				...state,
				loading: false,
				receivers: deletePaginated(state.receivers, action.payload),
				searchResults: deleteFromState(state.searchResults, action.payload),
			};
		case RECEIVER_SEARCHED:
			return {
				...state,
				loading: false,
				searchResults: action.payload['result'],
			};
		case RECEIVER_GROUP_EDITED:
			return {
				...state,
				loading: false,
				groups: editedUpdate(state.groups, action.payload, 'group'),
			};
		case RECEIVER_GROUP_DELETE:
			return {
				...state,
				loading: false,
				groups: deleteFromState(state.groups, action.payload, 'group'),
			};
		case RECEIVER_GROUP_LOADED:
			return {
				...state,
				loading: false,
				groups: action.payload,
			};
		case BULK_RECEIVER_UPLOADED:
			obj = [...state.groups];
			result = [...obj.filter((g) => g.group.id === action.payload.group)];
			result = result.length > 0 ? { ...result[0] } : {};
			result['count'] = result.count + action.payload.added;
			obj = [result, ...obj.filter((g) => g.group.id !== action.payload.group)];
			return {
				...state,
				groups: obj,
				loading: false,
				isSuccessful: true,
			};
		case BULK_RECEIVER_FAILED:
			return {
				...state,
				loading: false,
				isSuccessful: false,
			};
		case RECEIVER_LOADED:
			return {
				...state,
				loading: false,
				receivers: action.payload,
			};
		case RECEIVER_FAILED:
		case RECEIVER_GROUP_FAILED:
			return {
				...state,
				loading: false,
			};
		case LOGOUT:
			return {
				loading: false,
				groups: null,
				receivers: null,
				searchResults: [],
				isSuccessful: false,
			};
		default:
			return state;
	}
}
