import {
	BIZTYPE_LOADING,
	BIZTYPE_LOADED,
	BIZTYPE_FAILED,
	COUNTRIES_LOADING,
	COUNTRIES_LOADED,
	COUNTRIES_FAILED,
	SENDER_POSITION_LOADING,
	SENDER_POSITION_LOADED,
	SENDER_POSITION_FAILED,
	SERVICEPROVIDER_LOADING,
	SERVICEPROVIDER_LOADED,
	SERVICEPROVIDER_FAILED,
	PAYMENT_TYPES_LOADNIG,
	PAYMENT_TYPES_LOADED,
	PAYMENT_TYPES_FAILED,
	FUND_SOURCE_LOADING,
	FUND_SOURCE_LOADED,
	FUND_SOURCE_FAILED,
	FEETYPE_LOADING,
	FEETYPE_LOADED,
	FEETYPE_FAILED,
	DASHBOARD_LOADING,
	DASHBOARD_LOADED,
	DASHBOARD_FAILED,
	UPDATE_APP_DOMAIN,
	LOGOUT,
} from '../types';

const initialState = {
	loading: false,
	senderPositions: [],
	bizTypes: [],
	countries: [],
	serviceProviders: [],
	paymentTypes: [],
	fundSource: [],
	feeTypes: [],
	dashboard: null,
	appName: '',
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SERVICEPROVIDER_LOADING:
		case SENDER_POSITION_LOADING:
		case PAYMENT_TYPES_LOADNIG:
		case FUND_SOURCE_LOADING:
		case COUNTRIES_LOADING:
		case FEETYPE_LOADING:
		case BIZTYPE_LOADING:
		case DASHBOARD_LOADING:
			return {
				...state,
				loading: true,
			};
		case DASHBOARD_LOADED:
			return {
				...state,
				loading: false,
				dashboard: action.payload,
			};
		case PAYMENT_TYPES_LOADED:
			return {
				...state,
				loading: false,
				paymentTypes: action.payload,
			};
		case SERVICEPROVIDER_LOADED:
			return {
				...state,
				loading: false,
				serviceProviders: action.payload,
			};
		case COUNTRIES_LOADED:
			return {
				...state,
				loading: false,
				countries: action.payload,
			};
		case BIZTYPE_LOADED:
			return {
				...state,
				loading: false,
				bizTypes: action.payload,
			};
		case SENDER_POSITION_LOADED:
			return {
				...state,
				loading: false,
				senderPositions: action.payload,
			};
		case FUND_SOURCE_LOADED:
			return {
				...state,
				loading: false,
				fundSource: action.payload,
			};
		case FEETYPE_LOADED:
			return {
				...state,
				loading: false,
				feeTypes: action.payload,
			};
		case UPDATE_APP_DOMAIN:
			return {
				...state,
				appName: action.payload,
			};
		case PAYMENT_TYPES_FAILED:
		case SENDER_POSITION_FAILED:
		case SERVICEPROVIDER_FAILED:
		case FUND_SOURCE_FAILED:
		case COUNTRIES_FAILED:
		case FEETYPE_FAILED:
		case BIZTYPE_FAILED:
		case DASHBOARD_FAILED:
			return {
				...state,
				loading: false,
			};
		case LOGOUT:
			return {
				...state,
				dashboard: null,
			};
		default:
			return state;
	}
}
