/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
    'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

// Registration
export const REGISTRATION_LOADING = 'REGISTRATION_LOADING';
export const REGISTRATION_SUCCESSFUL = 'REGISTRATION_SUCCESSFUL';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const REGISTRATION_COMPLETED = 'REGISTRATION_COMPLETED';
export const REGISTRATION_FAILED = 'REGISTRATION_FAILED';

// Authentication
export const AUTH_LOADING = 'AUTH_LOADING';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const AUTH_FAILED = 'AUTH_FAILED';
export const LOGOUT = 'LOGOUT';

// User
export const USER_LOADED = 'USER_LOADED';
export const USER_EDITED = 'USER_EDITED';
export const USER_FAILED = 'USER_FAILED';

//Authoriser
export const AUTHORISER_LOADING = 'AUTHORISERS_LOADING';
export const AUTHORISER_LOADED = 'AUTHORISER_LOADED';
export const AUTHORISER_CREATED = 'AUTHORISER_CREATED';
export const AUTHORISER_EDITED = 'AUTHORISER_EDITED';
export const AUTHORISER_FAILED = 'AUTHORISER_FAILED';
export const AUTHORISER_CHANGE_STATUS = 'AUTHORISER_CHANGE_STATUS';

// Receiver
export const RECEIVER_LOADING = 'RECEIVER_LOADING';
export const RECEIVER_LOADED = 'RECEIVER_LOADED';
export const RECEIVER_CREATED = 'RECEIVER_CREATED';
export const RECEIVER_EDITED = 'RECEIVER_EDITED';
export const RECEIVER_DELETED = 'RECEIVER_DELETED';
export const RECEIVER_SEARCHED = 'RECEIVER_SEARCHED';
export const RECEIVER_FAILED = 'RECEIVER_FAILED';

// Receiver Group
export const RECEIVER_GROUP_LOADING = 'RECIEVER_GROUP_LOADING';
export const RECEIVER_GROUP_LOADED = 'RECEIVER_GROUP_LOADED';
export const RECEIVER_GROUP_CREATED = 'RECEIVER_GROUP_CREATED';
export const RECEIVER_GROUP_EDITED = 'RECEIVER_GROUP_EDITED';
export const RECEIVER_GROUP_DELETE = 'RECEIVER_GROUP_DELETE';
export const RECEIVER_GROUP_FAILED = 'RECEIVER_GROUP_FAILED';

export const BULK_RECEIVER_UPLOADING = 'BULK_RECEIVER_UPLOADING';
export const BULK_RECEIVER_UPLOADED = 'BULK_RECEIVER_UPLOADED';
export const BULK_RECEIVER_FAILED = 'BULK_RECEIVER_FAILED';

// Payment Auth rule
export const PAYRULE_LOADING = 'PAYRULE_LOADING';
export const PAYRULE_LOADED = 'PAYRULE_LOADED';
export const PAYRULE_CREATED = 'PAYRULE_CREATED';
export const PAYRULE_EDITED = 'PAYRULE_EDITED';
export const PAYRULE_DELETE = 'PAYRULE_DELETE';
export const PAYRULE_FAILED = 'PAYRULE_FAILED';

// Reset Password
export const RESETTING_PASSWORD = 'RESETTING_PASSWORD';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILED = 'PASSWORD_RESET_FAILED';

//SYSTEM
export const BIZTYPE_LOADING = 'BIZTYPE_LOADING';
export const BIZTYPE_LOADED = 'BIZTYPE_LOADED';
export const BIZTYPE_FAILED = 'BIZTYPE_FAILED';

export const SENDER_POSITION_LOADING = 'SENDER_POSITION_LOADING';
export const SENDER_POSITION_LOADED = 'SENDER_POSITION_LOADED';
export const SENDER_POSITION_FAILED = 'SENDER_POSITION_FAILED';

export const COUNTRIES_LOADING = 'COUNTRIES_LOADING';
export const COUNTRIES_LOADED = 'COUNTRIES_LOADED';
export const COUNTRIES_FAILED = 'COUNTRIES_FAILED';

export const ACCOUNT_LOADING = 'ACCOUNT_LOADING';
export const ACCOUNT_LOADED = 'ACCOUNT_LOADED';
export const ACCOUNT_FAILED = 'ACCOUNT_FAILED';

export const PAYMENT_TYPES_LOADNIG = 'PAYMENT_TYPES_LOADNIG';
export const PAYMENT_TYPES_LOADED = 'PAYMENT_TYPES_LOADED';
export const PAYMENT_TYPES_FAILED = 'PAYMENT_TYPES_FAILED';

export const SERVICEPROVIDER_LOADING = 'SERVICEPROVIDER_LOADING';
export const SERVICEPROVIDER_LOADED = 'SERVIDEPROVIDER_LOADED';
export const SERVICEPROVIDER_FAILED = 'SERVICEPROVIDER_FAILED';

export const FUND_SOURCE_LOADING = 'FUND_SOURCE_LOADING';
export const FUND_SOURCE_LOADED = 'FUND_SOURCE_LOADED';
export const FUND_SOURCE_FAILED = 'FUND_SOURCE_FAILED';

export const WALLET_LOADING = 'WALLET_LOADING';
export const WALLET_FUND_POSTED = 'WALLET_FUND_POSTED';
export const WALLET_TRANS_LOADED = 'WALLET_TRANS_LOADED';
export const WALLET_FUND_FAILED = 'WALLET_FUND_FAILED';

export const SENDER_FEE_LOADING = 'SENDER_FEE_LOADING';
export const SENDER_FEE_LOADED = 'SENDER_FEE_LOADED';
export const SENDER_FEE_EDITED = 'SENDER_FEE_EDITED';
export const SENDER_FEE_CREATED = 'SENDER_FEE_CREATED';
export const SENDER_FEE_DELETED = 'SENDER_FEE_DELETED';
export const SENDER_FEE_FAILED = 'SENDER_FEE_FAILED';

export const FEE_SCALE_LOADING = 'FEE_SCALE_LOADING';
export const FEE_SCALE_LOADED = 'FEE_SCALE_LOADED';
export const FEE_SCALE_EDITED = 'FEE_SCALE_EDITED';
export const FEE_SCALE_DELETED = 'FEE_SCALE_DELETED';
export const FEE_SCALE_CREATED = 'FEE_SCALE_CREATED';
export const FEE_SCALE_FAILED = 'FEE_SCALE_FAILED';

export const FEETYPE_LOADING = 'FEETYPE_LOADING';
export const FEETYPE_LOADED = 'FEETYPE_LOADED';
export const FEETYPE_FAILED = 'FEETYPE_FAILED';

export const PAYMENT_CANCELLED = 'PAYMENT_CANCELLED';
export const CREATING_PAYMENT = 'CREATING_PAYMENT';
export const PAYMENT_LOADING = 'PAYMENT_LOADING';
export const PAYMENT_DETAILS = 'PAYMENT_DETAILS';
export const PAYMENT_POSTED = 'PAYMENT_POSTED';
export const PAYMENT_LOADED = 'PAYMENT_LOADED';
export const PAYMENT_FAILED = 'PAYMENT_FAILED';

export const PRODUCT_LOADING = 'PRODUCT_LOADING';
export const PRODUCT_LOADED = 'PRODUCT_LOADED';
export const PRODUCT_FAILED = 'PRODUCT_FAILED';

export const AUTH_PROVIDER_LOADING = 'AUTH_PROVIDER_LOADING';
export const AUTH_PROVIDER_LOADED = 'AUTH_PROVIDER_LOADED';
export const AUTH_PROVIDER_FAILED = 'AUTH_PROVIDER_FAILED';

export const PRODUCT_TRANSACTION_LOADING = 'PRODUCT_TRANSACTION_LOADING';
export const PRODUCT_TRANSACTION_LOADED = 'PRODUCT_TRANSACTION_LOADED';
export const PRODUCT_TRANSACTION_FAILED = 'PRODUCT_TRANSACTION_FAILED';
export const DOWNLOADED_RECEIVED = 'DOWNLOADED_RECEIVED';

export const TRANSACTION_REVERSAL_LOADING = 'TRANSACTION_REVERSAL_LOADING';
export const TRANSACTION_REVERSAL_SUCCESS = 'TRANSACTION_REVERSAL_SUCCESS';
export const TRANSACTION_REVERSAL_FAILED = 'TRANSACTION_REVERSAL_FAILED';

export const TRANSACTION_QUERY_LOADING = 'TRANSACTION_QUERY_LOADING';
export const TRANSACTION_QUERY_LOADED = 'TRANSACTION_QUERY_LOADED';
export const TRANSACTION_QUERY_FAILED = 'TRANSACTION_QUERY_FAILED';

export const DASHBOARD_LOADING = 'DASHBOARD_LOADING';
export const DASHBOARD_LOADED = 'DASHBOARD_LOADED';
export const DASHBOARD_FAILED = 'DASHBOARD_FAILED';

export const PAYMENTMETHOD_LOADING = 'PAYMENTMETHOD_LOADING';
export const PAYMENTMETHOD_LOADED = 'PAYMENTMETHOD_LOADED';
export const PAYMENTMETHOD_FAILED = 'PAYMENTMETHOD_FAILED';

export const PROVIDERS_LOADING = 'PROVIDERS_LOADING';
export const PROVIDERS_LOADED = 'PROVIDERS_LOADED';
export const PROVIDERS_CREATED = 'PROVIDERS_CREATED';
export const PROVIDERS_EDITED = 'PROVIDERS_EDITED';
export const PROVIDERS_DELETED = 'PROVIDERS_DELETED';
export const PROVIDERS_FAILED = 'PROVIDERS_FAILED';
export const PROVIDERS_DETAILS = 'PROVIDERS_DETAILS';
export const PROVIDERS_DETAILS_RESET = 'PROVIDERS_DETAILS_RESET';

export const SP_PRODUCTS_LOADING = 'SP_PRODUCTS_LOADING';
export const SP_PRODUCTS_LOADED = 'SP_PRODUCTS_LOADED';
export const SP_PRODUCTS_CREATED = 'SP_PRODUCTS_CREATED';
export const SP_PRODUCTS_EDITED = 'SP_PRODUCTS_EDITED';
export const SP_PRODUCTS_DELETED = 'SP_PRODUCTS_DELETED';
export const SP_PRODUCTS_FAILED = 'SP_PRODUCTS_FAILED';

export const SP_MANAGER_LOADING = 'SP_MANAGER_LOADING';
export const SP_MANAGER_LOADED = 'SP_MANAGER_LOADED';
export const SP_MANAGER_CREATED = 'SP_MANAGER_CREATED';
export const SP_MANAGER_EDITED = 'SP_MANAGER_EDITED';
export const SP_MANAGER_FAILED = 'SP_MANAGER_FAILED';

export const FUNDS_LOADING = 'FUNDS_LOADING';
export const FUNDS_LOADED = 'FUNDS_LOADED';
export const FUNDS_DETAILS = 'FUNDS_DETAILS';
export const FUNDS_APPROVED = 'FUNDS_APPROVED';
export const FUNDS_FAILED = 'FUNDS_FAILED';

export const SETTLEMENT_LOADING = 'SETTLEMENT_LOADING';
export const SETTLEMENT_LOADED = 'SETTLEMENT_LOADED';
export const SETTLEMENT_FAILED = 'SETTLEMENT_FAILED';
export const SETTLEMENT_RESET = 'SETTLEMENT_RESET ';

export const PRODUCT_FEESCALE_LOADING = 'PRODUCT_FEESCALE_LOADING';
export const PRODUCT_FEESCALE_LOADED = 'PRODUCT_FEESCALE_LOADED';
export const PRODUCT_FEESCALE_CREATED = 'PRODUCT_FEESCALE_CREATED';
export const PRODUCT_FEESCALE_EDITED = 'PRODUCT_FEESCALE_EDITED';
export const PRODUCT_FEESCALE_DELETED = 'PRODUCT_FEESCALE_DELETED';
export const PRODUCT_FEESCALE_FAILED = 'PRODUCT_FEESCALE_FAILED';

export const PRODUCTCATEGORY_LOADING = 'PRODUCTCATEGORY_LOADING';
export const PRODUCTCATEGORY_LOADED = 'PRODUCTCATEGORY_LOADED';
export const PRODUCTCATEGORY_CREATED = 'PRODUCTCATEGORY_CREATED';
export const PRODUCTCATEGORY_EDITED = 'PRODUCTCATEGORY_EDITED';
export const PRODUCTCATEGORY_DELETED = 'PRODUCTCATEGORY_DELETED';
export const PRODUCTCATEGORY_FAILED = 'PRODUCTCATEGORY_FAILED';

export const KLASS_LOADING = 'KLASS_LOADING';
export const KLASS_LOADED = 'KLASS_LOADED';
export const KLASS_CREATED = 'KLASS_CREATED';
export const KLASS_EDITED = 'KLASS_EDITED';
export const KLASS_DELETED = 'KLASS_DELETED';
export const KLASS_FAILED = 'KLASS_FAILED';

export const KLASSGROUP_LOADING = 'KLASSGROUP_LOADING';
export const KLASSGROUP_LOADED = 'KLASSGROUP_LOADED';
export const KLASSGROUP_CREATED = 'KLASSGROUP_CREATED';
export const KLASSGROUP_DELETED = 'KLASSGROUP_DELETED';
export const KLASSGROUP_FAILED = 'KLASSGROUP_FAILED';

export const TRANSACTION_DAILY_REPORT_LOADING =
    'TRANSACTION_DAILY_REPORT_LOADING';
export const TRANSACTION_DAILY_REPORT_LOADED =
    'TRANSACTION_DAILY_REPORT_LOADED';
export const TRANSACTION_DAILY_REPORT_FAILED =
    'TRANSACTION_DAILY_REPORT_FAILED';

// WALLET TRANSACTIONS
export const WALLET_TRANSACTION_LOADED = 'WALLET_TRANSACTION_LOADED';
export const WALLET_ACCOUNT_SEARCHED = 'WALLET_ACCOUNT_SEARCHED';
export const WALLET_ACCOUNT_LOADED = 'WALLET_ACCOUNT_LOADED';
export const WALLET_FAILED = 'WALLET_FAILED';

export const RESET_STATUS = 'RESET_STATUS';
export const RESET_MESSAGE = 'RESET_MESSAGE';

export const SIDEBAR_DATA_LOADED = 'SIDEBAR_DATA_LOADED';

export const UPDATE_APP_DOMAIN = 'UPDATE_APP_DOMAIN';

export const BASE_URL = 'localhost:8080';